@import "../../styles/colors.scss";

.payment-dialog{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    .payment-dialog-title{
        color: $fontColor;
        font-size: $fontSize3;
        font-weight: bolder;
    }

    .payment-dialog-method{
        color: $fontColor;
        font-size: $fontSize2;
        font-weight: bold;
    }
    .payment-dialog-text{
        color: white;
        font-size: $fontSize1;
        max-width: 500px;
        margin-top: 20px;
        font-weight: bold;
    }

    .payment-dialog-img{
        height: 120px;
        width: 120px;
        margin: 15px 0px;
        img {
            height: 100%;
            width: 100%;
            fill: green;
        }
    }

    .spinner{
        width: auto !important;
        height: auto !important;
    }

    .payment-dialog-buttons{

        button{
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
            background-color: rgba(255,255,255,0.4);
            color: white;
            border: none;
            transition: all 0.2s ease-in-out;

            &:hover{
                background-color: rgba(255,255,255,0.3); 
            }
        }
    }
}