@import "../../styles/colors.scss";

.checkbox-field {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-wrap: wrap;
	> div {
		min-width: 49%;
	}
}
