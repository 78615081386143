@import "../styles/colors.scss";

.detail-page {
	height: 100%;
	display: grid;
	grid-template-rows: 50px calc(100% - 50px);
	grid-template-columns: calc(100% - 300px) 300px;
	grid-template-areas:
		"top top"
		"content side";

	@media(max-width: 900px){
		grid-template-areas: "top" "content" "side";
		grid-template-rows: 50px auto auto;
		grid-template-columns: 100%;
	}


	.detail-page-top {
		grid-area: top;
		height: 50px;
		margin-bottom: 20px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		.detail-page-top-back {
			position: absolute;
			top: 0;
			left: 0;
			height: 40px;
			margin: 5px;
			cursor: pointer;
			background-color: transparent;
			border: none;
			padding: 0;
			width: 40px;
			border-radius: 50px;
			transition: all 0.2s ease-in-out;

			&:hover {
				background-color: rgba(128, 128, 128, 0.2);
			}

			i {
				height: 100%;
				width: 100%;
				font-size: 28px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.detail-page-top-title {
			font-size: $fontSize3;
			color: $fontColor;
			font-weight: bold;
		}
	}

	.detail-page-side {
		grid-area: side;
		display: flex;
		flex-direction: column;
		padding: 15px;
		overflow: auto;

		@media(max-width: 900px){
			justify-content: center;
			align-items: center;
			max-width: 700px;
		}


		.login-warning {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			button {
				background-color: transparent;
				border: 1px solid black;
				border-radius: 10px;
				cursor: pointer;
				transition: all 0.2s ease-in-out;
				padding: 5px;

				&:hover {
					background-color: rgba(128, 128, 128, 0.2);
				}
			}
		}

		.detail-page-side-wrapper {
			display: flex;
			flex-direction: column;
			padding: 15px;
			background-color: white;
			border-radius: 15px;

			.detail-page-side-payment-information{

				.payment-add-to-shopping-cart{
					button{
						background-color: rgba(210,210,210,1);
						padding: 10px;
						border: none;
						border-radius: 10px;
						cursor: pointer;
						transition: all 0.2s ease-in-out;

						&:hover{
							background-color: rgba(210,210,210,0.5);
						}
					}
				}

				.payment-price-total{
					display: grid;
					grid-template-columns: 100px calc(100% - 100px);
					grid-template-areas: "label value";
					margin-bottom: 10px;

					.payment-total-label{
						grid-area: label;
						text-align: start;
						color: $fontColor;
						font-weight: bold;
					}

					.payment-total-price{
						grid-area: value;
						padding-left: 8px;
						text-align: start;
					}
				}

				.payment-conclusion{
					border-bottom: 1px solid rgba(0,0,0,0.7);
					padding-bottom: 5px;
					margin-bottom: 5px;
					display: grid;
					grid-template-areas: "labels values";
					grid-template-columns: 100px calc(100% - 100px);

					.payment-labels{
						grid-area: labels;

						> div{
							height: 50%;
							text-align: start;
							color: $fontColor;
						}
					}

					.payment-amount-values{
						grid-area: values;
						padding-left: 8px;
						
						> div{
							height: 50%;
							text-align: start;
						}
					}
				}
			}

			.detail-page-side-payment-select{
				.payment-time{
					margin-bottom: 5px;
					.DateRangePicker{
						width: 100%;

						.date-value-field{
							.date-value{

							}
							.date-capacity{
								overflow: hidden;
								display: flex;
								justify-content: center;
								align-items: center;
								.date-capacity-value{
									margin-right: 5px;
									color: rgba(128,128,128,0.7);
								}

								i{
									font-size: 15px;
									color: rgba(128,128,128,0.7);
								}
							}

						}

						.DateRangePickerInput{
							width: 100%;
							border-radius: 10px;
						}
					}
				}

				.payment-amount{
					display: flex;
					margin-bottom: 5px;
					.payment-amount-label{
						padding-right: 5px;
						display: flex;
						color: $fontColor;
						justify-content: center;
						align-items: center;
					}

					.payment-amount-values {
						display: flex;
						.payment-amount-input{
							flex-basis: 0;
							background-color: rgba(230,230,230,1);
							color: black;
							border-radius: 10px;
							height: 26px;
							padding: 0;
							width: 20px;
							text-align: center;
						}

						.payment-amount-max{
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}

			.detail-page-side-wishlist {
				position: relative;
				padding-bottom: 5px;
				margin-bottom: 5px;
				border-bottom: 1px solid rgba(0,0,0,0.7);

				.detail-page-side-wishlist-star {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 0;
				}

				.detail-page-side-wishlist-button {
					position: relative;
					width: 100%;
					height: 100%;
					padding: 10px;
					border-radius: 15px;
					z-index: 1;
					background-color: transparent;
					border: 1px solid rgba(128, 128, 128, 0.8);
					cursor: pointer;
					transition: all 0.2s ease-in-out;

					@media(max-width: 900px){
						min-width: 250px;
					}
		

					&.active {
						background-color: rgba(128, 128, 128, 0.2);
					}

					&:hover {
						background-color: rgba(128, 128, 128, 0.2);
					}

					.spinner {
						width: auto;
						height: 29px;
						min-width: 0px;
						min-height: 0px;
					}
				}
			}
		}
	}

	.detail-page-content {
		grid-area: content;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		.detail-page-content-details {
			display: flex;

			.detail-page-content-details-slider {
				width: 40%;
				padding: 20px;

				.slider-wrapper {
					height: 100%;
				}
			}

			.detail-page-content-details-properties {
				width: 60%;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.detail-page-property-title {
					font-size: $fontSize2;
					color: $fontColor;
					text-align: start;
					font-weight: bold;
					margin-top: 10px;
				}

				.detail-page-property {
					display: flex;
					padding: 10px;

					.detail-page-property-name {
						width: 100px;
						display: flex;
						font-size: $fontSize1;
						color: $fontColor;
						justify-content: flex-start;
					}

					.detail-page-property-value {
						width: auto;
						display: flex;
						justify-content: flex-start;
					}
				}

				.detail-page-property-tags {
					display: flex;
					flex-wrap: wrap;
					.detail-page-tag {
						border: 1px solid black;
						border-radius: 10px;
						padding: 5px;
						margin: 5px;
					}
				}
			}
		}

		.detail-page-content-description {
			padding: 15px;
			.detail-page-content-description-title {
				font-size: $fontSize2;
				color: $fontColor;
				margin-bottom: 20px;
				font-weight: bold;
			}
		}
	}
}
