@import "../../styles/colors.scss";

.cart-item{
    display: grid;
    grid-template-areas: "img title title" "img details payment" "img line line" "img conclusion conclusion";
    background-color: white;
    grid-template-columns: 200px auto;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 900px;
    width: calc(100% - 20px);

    @media(max-width: 600px){
        grid-template-areas: "title title" "img details" "img payment" "img line" "conclusion conclusion";
        grid-template-columns: 150px auto;
    }


    &.invalid{
        border: 1px solid red;
    }

    &:first-child{
        margin: 0 auto;
    }

    .cart-item-line{
        grid-area: line;
        height: 1px;
        background-color: black;
        margin: 5px 0 0 0;
    }

    .cart-item-delete{
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        color: red;
        padding: 5px;
        margin: 5px;
        border: none;
        border-radius: 50px;
        cursor: pointer;

    }

    .cart-item-conclusion{
        grid-area: conclusion;
        display: flex;
        margin-top: 10px;
        justify-content: center;

        .shoppingcart-time{
            margin-bottom: 5px;
            .DateRangePicker{
                width: 100%;

                .date-value-field{
                    .date-value{

                    }
                    .date-capacity{
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .date-capacity-value{
                            margin-right: 5px;
                            color: rgba(128,128,128,0.7);
                        }

                        i{
                            font-size: 15px;
                            color: rgba(128,128,128,0.7);
                        }
                    }

                }


                .DateRangePickerInput{
                    width: 100%;
                    border-radius: 10px;
                }
            }
        }

        .cart-item-conclusion-total-label{
            padding: 5px;
            color: $fontColor;
            font-weight: bold;
            margin-left: 20px;
        }

        .cart-item-conclusion-total-value{
            padding: 5px;
            font-weight: bold;
        }
    }

    .cart-item-img{
        grid-area: img;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            max-width: 100%;
            max-height: 100%;
            border-radius: 10px;
        }
    }

    .cart-item-details-title{
        grid-area: title;
        color: $fontColor;
        font-size: $fontSize2;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .cart-item-details{
        grid-area: details;
        display: grid;
        grid-template-areas: "label value";
        grid-template-columns: max-content auto;
        margin-left: 10px;

        .cart-item-details-labels{
            grid-area: label;
            margin-right: 10px;
            > div{
                color: $fontColor;
                text-align: start;
            }
        }

        .cart-item-details-values{
            grid-area: value;
            > div{
                text-align: start;
            }
        }
    }

    .cart-item-payment{
        grid-area: payment;
        display: grid;
        grid-template-areas: "label value";
        grid-template-columns: max-content auto;

        @media(max-width: 600px){
            margin-left: 10px;
            margin-top: 10px;
        }


        .cart-item-payment-labels{
            grid-area: label;
            margin-right: 10px;
            > div{
                color: $fontColor;
                text-align: start;
            }

        }

        .cart-item-payment-values{
            grid-area: value;
            > div{
                text-align: start;
            }

            .cart-item-payment-amount-value {
                display: flex;
                .cart-item-amount-input{
                    flex-basis: 0;
                    background-color: rgba(230,230,230,1);
                    color: black;
                    border-radius: 10px;
                    height: 26px;
                    padding: 0;
                    width: 20px;
                    text-align: center;
                }

                .cart-item-amount-max{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

        }
    }
}