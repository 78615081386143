@import "../../styles/colors.scss";

.item {
	height: 100%;
	width: 200px !important;
	.item-top {
		margin: 10px;
	}
	.item-title {
		color: $fontColor;
		font-weight: bold;
		font-size: $fontSize2;
	}
	.item-pic {
		height: calc(100% - 50px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		img {
			max-width: 100%;
			max-height: 100%;
			border-radius: 20px;
		}
	}
}
