@import "../../styles/colors.scss";

.checkbox-input {
	display: flex;

	.checkbox-input-label {
		flex-grow: 1;
		padding: 2px;
		display: flex;
		justify-content: flex-start;
		margin-right: 5px;
		word-wrap: break-word;
		overflow: hidden;
	}
	.checkbox-input-button {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		border: 1px solid black;

		display: flex;
		justify-content: center;
		align-items: center;

		border-radius: 50px;
		cursor: pointer;
	}
}
