@import "../../styles/colors.scss";

.wishlist-slider {
	height: 300px;
	display: grid;
	grid-template-rows: 50px calc(100% - 50px);
	grid-template-columns: 100%;

	.slider-top {
		position: relative;
		margin: 20px 0px 0px 40px;
		.slider-title {
			color: $fontColor;
			font-weight: bold;
			font-size: $fontSize2;
			text-align:center;
		}

		.slider-show-all {
			position: absolute;
			bottom: 0;
			right: 0;

			button {
				position: relative;

				z-index: 2;
				background-color: transparent;
				border: none;
				color: rgb(30, 126, 216);
				cursor: pointer;
				border-bottom: 1px solid transparent;
				transition: all 0.2s ease-in-out;

				&:hover {
					border-bottom: 1px solid rgb(30, 126, 216);
				}
			}
		}
	}

	.slider-content:hover .slider-item {
		transform: translateX(-10%);
	}

	.slider-item:hover ~ .slider-item {
		transform: translateX(10%);
	}

	.slider-item:hover {
		transform: scale(1.2) !important;
	}

	.slider-content {
		display: flex;
		.slider-item {
			height: 100%;
			background: transparent;
			flex: 0 0 19.7%;
			text-align: center;
			margin: 0 20px;
			transition: transform 300ms ease 100ms;

			&:last-child{
				margin-right: 80px;
			}
		}
	}

	.slider-content {
		width: 100%;
		height: 100%;
		/*this gives the dimension of slider container*/
		/*these values will make the container as big as the parent div*/
		overflow: visible;
		/*important parameter to not disrupt the container when slides increases in coverflow and other effect*/
	}
	.swiper-slide {
		max-width: 200px;
		height: 300px;
		/*this one holds the dimension of each slides and can be changed according to the user wish*/
	}
}
