@import "../../styles/colors.scss";

.map{

    .map-wrapper{
        height: 300px;
    }
    .marker {
        background-image: url('icon.jpg');
        background-size: cover;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
      }

      .mapboxgl-popup {
        max-width: 200px;
      }
      
      .mapboxgl-popup-content {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
      }

}