@import "../../styles/colors.scss";

.select-box {
	position: relative;
	display: flex;
	background-color: rgba(128, 128, 128, 0.1);
	width: 100%;
	padding: 6px;
	border-radius: 10px;
	cursor: pointer;

	* {
		font-size: $fontSize1;
	}

	.select-box-value {
		flex-grow: 1;
	}

	.select-box-arrow {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;
	}

	.select-box-menu {
		position: absolute;
		z-index: 2;
		top: 100%;
		width: calc(100% - 12px);
		border-radius: 10px;

		background-color: rgba(200, 200, 200, 1);
		overflow: hidden;
		display: none;

		.select-box-menu-item {
			padding: 5px;
			transition: all 0.1s ease-in-out;
			cursor: pointer;

			&:hover {
				background-color: rgba(128, 128, 128, 0.2);
			}
		}
	}
}

.select-box-open {
	.select-box-menu {
		display: block;
	}
}
