@import "../styles/colors.scss";

.order-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    .order-page-title{
        color: $fontColor;
        font-weight: bold;
        font-size: $fontSize3;
        margin-bottom: 20px;
    }

    .order-page-items{
        max-width: 900px;
        width: 100%;
        .order-item{
            border-radius: 10px;
            margin: 20px 0;
            background-color: white;
            display: grid;
            grid-template-columns: min-content auto;
            grid-template-areas: "method personal" "method payment";
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover{
                background-color: rgba(255,255,255,0.4);
            }

            .order-item-method{
                grid-area: method;
                overflow: hidden;
                display: flex;
                padding: 10px;
                justify-content: center;
                align-items: center;

                img{
                    width: 100px;
                }

            }

            .order-item-personal-infos{
                grid-area: personal;
                padding: 10px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                border-bottom: 1px solid rgba(0,0,0,0.6);
                justify-content: center;

                .order-item-payment-surname{
                    color: $fontColor;
                    margin-right: 10px;
                }

                .order-item-payment-prename{
                    color: $fontColor;
                    margin-right: 5px;
                }

                .order-item-payment-email{

                }
            }

            .order-item-payment-infos{
                padding: 10px;
                grid-area: payment;

                .order-item-payment-total{
                    display: flex;
                    justify-content: center;
                    .order-item-payment-total-label{
                        color: $fontColor;
                        font-weight: bold;
                        margin-right: 5px;
                    }
                    .order-item-payment-total-value{
                        font-weight: bold;
                    }
                }

                .order-item-payment-state{
                }
            }
        }
    }
}