@import "../styles/colors.scss";

.payment-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .payment-top{
        display: flex;
        width: 100%;
        position: relative;
        justify-content: center;
        .detail-page-top-back{
            position: absolute;
            top: 0;
            left: 0;
            margin: 10px;
            background-color: transparent;
            border: none;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            padding: 5px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            i{
                font-size: $fontSize3;
            }

            &:hover{
                background-color: rgba(0,0,0,0.1);
            }
        }

        .payment-title{
            color: $fontColor;
            font-weight: bold;
            font-size: $fontSize3;
            padding: 10px;
        }

    }

    .payment-content{
        padding: 50px;
        max-width: 700px;
        height: calc(100% - 57px - 100px); 
        overflow-y: scroll;
        .payment-information{
            margin-bottom: 20px;

            .payment-information-title{
                text-align: start;
                color: $fontColor;
                font-weight: bold;
            }
            .payment-input{
                padding: 10px;
                display: flex;
                flex-wrap: wrap;
                .payment-input-label{
                    color: $fontColor;
                }

                .payment-input-value{
                    background-color: white;
                    border-radius: 10px;
                    color: black;
                    transition: all 0.2s ease-in-out;
                    &:hover{
                        background-color: rgba(255,255,255,0.5);
                    }
                }
            }
        }

        .payment-paymethod{

            margin-bottom: 20px;
            .payment-paymethod-title{
                text-align: start;
                color: $fontColor;
                font-weight: bold;
            }

            .payment-paymethod-items{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .payment-method{
                    cursor: pointer;
                    width: 150px;
                    margin: 10px;
                    border: 1px solid rgba(0,0,0,0.2);
                    background-color: rgba(100,100,100,0.1);
                    border-radius: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    transition: all 0.2s ease-in-out;

                    &.active{
                        border-color: $fontColor;
                        background-color: rgba(0,0,0,0.2) !important;
                    }

                    &:hover{
                        background-color: rgba(0,0,0,0.1);
                    }

                    .payment-method-img{
                        width: 100%;
                        img{
                            width: 100%;
                        }

                    }

                    .payment-method-label{
                        align-self: flex-end;
                        color: $fontColor;
                        font-weight: bold;

                    }
                }
            }
        }

        .payment-creditcard{
            display: none;
            margin-bottom: 20px;
            &.active{
                display: block;
            }

            .payment-credit-title{
                color: $fontColor;
                font-weight: bold;
                text-align: start;
            }
            .creditcard-submit{
                background-color: rgba(0,0,0,0.1);
                border-radius: 10px;
                border: none;
                cursor: pointer;
                width: auto;
                padding: 10px;
                transition: all 0.2s ease-in-out;

                &:disabled{
                    cursor: default;

                    &:hover{
                        background-color: rgba(0,0,0,0.1);
                    }
                }

                &:hover{
                    background-color: rgba(0,0,0,0.2);
                }
            }
            .payment-credit-wrapper{
                width: 400px;
                margin: 0 auto;
                overflow: hidden;

                @media(max-width: 500px){
                    width: 300px;
                }
            }
        }

        .payment-warning{
            display: none;

            &.active{
                display: block;
            }
        }

        .payment-conclusion{
            display: none;

            &.active{
                display: block;
            }

            .payment-conclusion-title{
                text-align: start;
                color: $fontColor;
                font-weight: bold;
            }

            .payment-conclusion-items{
                display: grid;
                padding: 10px;
                grid-template-areas: "names times amounts totals";
                border-bottom: 1px solid black;
                margin-bottom: 10px;

                .payment-item-title{
                    font-weight: bold;
                    color: $fontColor;
                }

                .payment-item-names{
                    grid-area: names;
                    .payment-item-name{
                        text-align: start;
                    }
                }
                .payment-item-times{
                    grid-area: times;
                    .payment-item-time{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;

                        @media(max-width: 500px){
                            padding: 0 5px;
                        }
            

                        .payment-item-from{}

                        .payment-item-gap{
                            margin: 0px 5px;
                        }

                        .payment-item-to{}
                    }
                }
                .payment-item-amounts{
                    grid-area: amounts;

                }
                .payment-item-totals{
                    grid-area: totals;

                    @media(max-width: 500px){
                        padding: 0 5px;
                    }
        
                }
                

            }

            .payment-conclusion-pay{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                justify-content: center;
                .payment-conclusion-pay-total{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 5px;

                    .payment-conclusion-pay-total-label{
                        color: $fontColor;
                        margin-right: 5px;

                    }
                    .payment-conclusion-pay-total-value{
                        font-weight: bold;
                    }
                }

                .payment-conclusion-pay-btn{
                    background-color: rgba(210,210,210,1);
                    padding: 10px;
                    border: none;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &:hover{
                        background-color: rgba(210,210,210,0.5);
                    }
                }
            }
        }

    }

    .payment-footer{
        width: 100%;
        max-width: 700px;
    }
}