.credit-card-input {
    margin-top: 20px;
    .cc {
        margin: 10px;
        width: calc(100% - 20px);
        background-image: radial-gradient(circle, #3d3b3f, #39383c, #363438, #323135, #2f2e32);
        border-radius: 10px;
        padding-top: 56.66%;
        position: relative;

        & > div {
            position: absolute;
            color: white;
            top: 0;
            height: 100%;
            width: 100%;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(var(--black), 0.3);
            & > div {
                position: absolute;
                padding: 5px;
                border-radius: 5px;
                border: 2px solid transparent;
                &.active {
                    border-color: var(--orange);
                }
            }

            .chip {
                width: 40px;
                top: 15%;
                left: 30px;
                padding: 0px;
                border: 0px;
                svg {
                    fill: #daa520;
                }
            }

            .number {
                transition: 0.3s all ease-in-out;
                bottom: 40%;
                left: 30px;
                text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
                font-weight: bold;
                color: #daa520;
            }
            .type {
                top: 20px;
                right: 30px;

                &.back {
                    top: auto;
                    bottom: 5px;
                    right: 15px;
                }
                i {
                    font-size: 1.8rem;
                    &::before {
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        display: initial;
                    }
                    &.im-credit-card::before {
                        background-image: linear-gradient(to right, white, white);
                    }
                    &.im-cc-mastercard::before {
                        background-image: linear-gradient(
                            to right,
                            #eb001b,
                            #f04510,
                            #f46707,
                            #f6840a,
                            #f79e1b
                        );
                    }
                    &.im-cc-visa::before {
                        background-image: linear-gradient(
                            to right,
                            #4e76d3,
                            #4e6ecc,
                            #4d65c5,
                            #4e5dbe,
                            #4e54b7
                        );
                    }
                    &.im-cc-amazon::before {
                        background-image: linear-gradient(
                            to right,
                            #ff9900,
                            #ef8f00,
                            #df8600,
                            #cf7c00,
                            #c07300
                        );
                    }
                }
            }
            .name-label {
                font-size: 0.8rem;
                color: rgba(var(--light), 0.3);
                bottom: 25%;
                left: 30px;
            }
            .date-label {
                font-size: 0.8rem;
                color: rgba(var(--light), 0.3);
                bottom: 25%;
                right: 30px;
            }
            .name {
                bottom: calc(20% - 1rem);
                left: 30px;
                text-transform: uppercase;
                font-size: 0.9rem;
            }
            .date {
                bottom: calc(20% - 1rem);
                right: 30px;
                font-size: 0.9rem;
            }
            .magnetic-stripe {
                height: 40px;
                width: 100%;
                margin: 0px;
                padding: 0px;
                background: black;
                border-radius: 0px;
                border: none;
                top: 15%;
            }
            .sign-field {
                width: 50%;
                background: white;
                margin: 0px;
                padding: 0px;
                border-radius: 0px;
                border: none;
                height: 15px;
                top: 45%;
                left: 5%;
                border-top: 7px solid rgba(88, 88, 88, 0.6);
                border-bottom: 7px solid rgba(88, 88, 88, 0.6);
            }
            .cvv {
                top: 45%;
                left: 60%;
            }
        }
    }
}
.cc-inputs {
    width: 400%;
    display: flex;
    overflow: hidden;
    margin: 20px 0px;
    margin-left: 0%;
    transition: 0.3s margin-left ease-in-out;

    input{
        background-color: white;
        border-radius: 10px;
        color: black;
    }

    .hb-input {
        margin: 5px;
    }

    .hidden {
        display: none;
    }
}
