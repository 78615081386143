.slider-content {
	display: flex;
	.slider-item {
		height: 100%;
		// width: 100%;
		background: transparent;
		//flex: 0 0 19.7%;
		text-align: center;
		margin: 0 2px;
		transition: transform 300ms ease 100ms;

		.item {
			width: auto !important;
		}
	}
}
