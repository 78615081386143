@import "../../styles/colors.scss";

.filter-page-item {
	display: inline-flex;
	width: 200px;
	height: 250px;
	padding: 15px;
	margin: 15px;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 15px;
	cursor: pointer;

	.filter-page-item-title {
		.filter-page-item-title-name {
			font-size: $fontSize2;
			color: $fontColor;
			font-weight: bold;
		}

		.filter-page-item-title-producer {
			font-size: $fontSize1;
			color: black;
		}
	}

	.filter-page-item-image {
		flex-grow: 1;
		overflow: hidden;
		img {
			border-radius: 10px;
			max-width: 100%;
			max-height: 100%;
		}
	}

	.filter-page-item-footer {
		.filter-page-item-footer-wheel-color {
			display: flex;
			margin-bottom: 10px;

			.filter-page-item-footer-wheel {
				width: 50%;
				padding: 5px;

				.filter-page-item-footer-wheel-title {
					color: $fontColor;
					font-weight: bold;
				}

				.filter-page-item-footer-wheel-value {
				}
			}

			.filter-page-item-footer-color {
				width: 50%;
				padding: 5px;
				border-left: 1px solid rgba(128, 128, 128, 0.8);
				.filter-page-item-footer-color-title {
					color: $fontColor;
					font-weight: bold;
				}

				.filter-page-item-footer-color-value {
				}
			}
		}

		.filter-page-item-footer-properties {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.filter-page-item-footer-property-title {
				color: $fontColor;
				font-weight: bold;
				margin-bottom: 10px;
				width: 100%;
			}

			.filter-page-item-footer-property {
				font-size: $fontSize1;
				padding: 0 10px 10px 10px;

				&:last-child {
					margin-bottom: 5px;
				}
			}
		}
	}
}
