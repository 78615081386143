@import "../styles/colors.scss";

.filter-page {
	display: grid;
	height: calc(100% - 50px);
	grid-template-rows: 50px calc(50% - 50px) 50%;
	grid-template-columns: auto auto 280px;
	grid-template-areas: "top top none" "content content side" "content content side";

	@media(max-width: 820px){
		grid-template-columns: auto;
		grid-template-rows: 50px calc(100% - 50px);
		grid-template-areas: "top" "content";
	}


	.filter-sidebar {
		grid-area: side;
		padding: 10px;
		@media(max-width: 820px){
			display: none;
		}


		::-webkit-scrollbar {
			width: 0;
			background-color: white;
		}

		.filter-sidebar-wrapper {
			padding: 10px;
			background-color: white;
			border-radius: 15px;
			height: calc(100% - 20px);
			.filter-sidebar-title {
				font-size: $fontSize3;

				color: $fontColor;
			}

			.filter-sidebar-filter {
				height: calc(100% - 37px);
				overflow-y: scroll;
				overflow-x: hidden;
			}
		}
	}

	.filter-topbar {
		grid-area: top;
		display: flex;
		justify-content: center;
		align-items: center;

		> div {
			margin-right: 20px;

			&:last-child {
				margin: 0;
			}
		}

		.filter-page-orderby {
			width: 200px;
		}

		.filter-page-search {
			width: 60%;

			@media(max-width: 820px){
				width: auto;
			}

			input{
				color: black;
			}
		}
	}

	.filter-content {
		grid-area: content;
		overflow-y: auto;
	}
}
