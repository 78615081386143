@import "../styles/colors.scss";

.shoppingcart{
    height: calc(100% - 50px);
    display: grid;
    grid-template-areas: "title" "items" "footer";
    grid-template-rows: max-content auto min-content;

    .cart-title{
        grid-area: title;
        color: $fontColor;
        font-weight: bolder;
        font-size: $fontSize3;
        padding: 10px;
    }  

    .cart-items{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-area: items;
        padding: 20px;
        overflow-y: auto;

        .cart-items-wrapper{
            flex-grow: 1;
            text-align: center;
        }
    }

    .cart-footer{
        grid-area: footer;
        background-color: white;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        box-shadow: 0px -8px 32px rgba(128,128,128,0.4);
        z-index: 1;

        .cart-footer-total{

            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 2px solid black;
            margin-right: 30px;
            .cart-footer-total-label{
                color: $fontColor;
                font-weight: bold;
                margin-right: 5px;
            }

            .cart-footer-total-value{
            }

        }

        .cart-footer-pay{
            background-color: rgba(210,210,210,1);
            padding: 10px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover{
                background-color: rgba(210,210,210,0.5);
            }
        }
    }
}