@import "../../styles/colors.scss";

.navigation {
	width: 100%;
	height: 150px;
	position: relative;

	transition: height 0.5s;

	.user-button-pane {
		display: none;
		background-color: white;
		padding: 10px;
		z-index: 5;
		border-radius: 10px;
		flex-direction: column;
		border: 1px solid black;

		.user-button-title {
			font-size: $fontSize1;
			border-bottom: 1px solid black;
			margin-bottom: 5px;
			color: $fontColor;
		}

		.user-button-content {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid black;
			padding-bottom: 5px;
			margin-bottom: 5px;

			button {
				width: calc(100% - 3px);
				border-radius: 10px;
				cursor: pointer;
				background-color: transparent;
				border: 1px solid black;
				margin: 2px;
				padding: 5px;
				transition: all 0.2s ease-in-out;

				&.matchpath {
					background-color: rgba(128, 128, 128, 0.2);
				}

				&:hover {
					background-color: rgba(128, 128, 128, 0.2);
				}
			}
		}

		.user-button-footer {
			display: flex;
			justify-content: center;

			button {
				background-color: transparent;
				border: 1px solid black;
				border-radius: 10px;
				cursor: pointer;
				padding: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all 0.2s ease-in-out;

				&:hover {
					background-color: rgba(128, 128, 128, 0.2);
				}
			}
		}
	}

	.open-user {
		position: absolute;
		right: 20px;
		top: 70px;
		//z-index: 54;
		display: flex;
	}

	.navigation-wrapper {
		background-color: white;
		overflow: hidden;
		height: 100%;
		display: flex;
		position: relative;
		flex-direction: column;

		.navigation-buttons {
			padding: 30px 80px 30px 30px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			transition: padding 0.5s;

			z-index: 2;

			@media(max-width: 500px){
				padding: 30px 10px 30px 30px;
			}

			.user-button {
				position: relative;
				img {
					height: 30px;
					border-radius: 50px;
				}
			}

			button {
				background-color: transparent;
				border: none;
				color: white;
				font-weight: bold;
				font-size: $fontSize2;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 20px;
				cursor: pointer;
				border-bottom: 2px solid rgba(0, 0, 0, 0);
				transition: all 0.2s ease-in-out;

				&:hover {
					border-color: white;
				}
			}

			.matchpath {
				border-color: white;
			}
		}

		.navigation-banner {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 0;
			height: 100%;

			img {
				min-width: 100%;
				height: 150px;

				filter: blur(1px);
				-webkit-filter: blur(1px);
			}
		}

		.navigation-logo {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
			height: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			@media(max-width: 500px){

				align-items: flex-end;
			}

			img {
				transition: height 0.5s;
				margin-left: 20px;
				height: 80px;

				@media(max-width: 500px){
					margin-left: 20px;
					margin-bottom: 25px;
					height: 50px;
				}
			}
		}
	}
}

.small-nav {
	height: 50px;

	.open-user {
		position: absolute;
		right: 20px;
		top: 50px;
		//z-index: 54;
		display: flex;
	}

	.navigation-wrapper {
		.navigation-buttons {
			padding: 10px 80px 0 0px;

			@media(max-width: 500px){
				padding: 10px 10px 0px 0px;
			}
		}

		.navigation-logo {

			img {
				height: 30px;

				@media(max-width: 500px){
					margin-bottom: 5px;
				}

				@media(max-width: 350px){
					display: none;
				}
	
	
			}
		}
	}
}
