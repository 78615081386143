@import "./styles/colors.scss";

body,
html,
.root {
	overflow: hidden;
}

.App {
	background-color: $primary;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.root {
	overflow: visible;
}

::-webkit-scrollbar {
	width: 4px;
	background-color: $primary;
}

::-webkit-scrollbar-track {
	background-color: $primary;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(50, 50, 50, 0.7);
	border-radius: 10px;
}

* {
	scrollbar-color: rgba(100, 100, 100, 0.7);
	scrollbar-width: 4px;
}

button:active,
button:focus {
	-moz-outline-style: none;
	outline: none;
	outline: 0;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}
