@import "../../styles/colors.scss";

.filter-foldable-item {
	position: relative;
	display: flex;

	flex-direction: column;
	width: 100%;

	.filter-foldable-item-content {
		overflow: hidden;
		padding: 10px;

		* {
			font-size: $fontSize1 !important;
			color: black !important;
		}

		.klumpp-input {
			margin: 0 !important;

			.klumpp-input-checkbox {
				box-shadow: none !important;
				border: 1px solid rgba(128, 128, 128, 1);
				font-size: 10px !important;
				width: 20px !important;
				height: 20px !important;

				.klumpp-input-field-content {
					padding: 0 !important;
					width: 20px !important;
					height: 20px !important;
				}
			}
		}
	}

	.filter-foldable-item-title {
		font-size: $fontSize2;
		display: flex;
		justify-content: flex-start;

		cursor: pointer;
	}

	.filter-foldable-item-button {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		background-color: transparent;
		border: none;

		transition: all 0.2s ease-in-out;

		&:hover {
			transform: rotate(180deg);
		}
	}
}
.filter-foldable-item-up {
	button {
		transform: rotate(180deg);
	}

	button:hover {
		transform: rotate(0deg) !important;
	}

	.filter-foldable-item-content {
		display: none;
	}
}
