@import "../../styles/colors.scss";

.text-input {
	width: 100%;
	background-color: rgba(128, 128, 128, 0.1);
	font-size: $fontSize1;
	padding: 8px;
	border-radius: 10px;
	border: none;
	transition: all 0.2s ease-in-out;

	&:hover {
		background-color: rgba(128, 128, 128, 0.2);
	}

	&:active,
	&:focus {
		outline: none;
	}
}
